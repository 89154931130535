import React, {Component} from "react";
import './Sidebar.css'
import {NavLink, useLocation, withRouter} from 'react-router-dom'
import {connect} from "react-redux"
import {generatePortalSpecificUrl} from "../../helpers/urlGenerators";
import {SidebarProps, SidebarState} from "../../interfaces/sidebar";
import _, {get, find} from "lodash";
import * as actions from "../../store/actions/portal";
import { axiosObj } from '../../Axios';
import CreatePortal from "../Portals/createPortal";
import GroupPortals from "../Portals/GroupPortals";
import {Container} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinusCircle, faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import {ProjectProps} from "../../interfaces/portal";
import {clear_project_state, get_project_list} from "../../store/actions";
import {remove_selected_portal, set_portal_selected} from "../../store/actions/portal";
import LocalStorageWrapped from "../../LocalStorageWrapped";
import {clear_selected_project_state, set_project_selected} from "../../store/actions/project";
import CreateMultiPortalUser from "../Portals/CreateMultiPortalUser";


class Sidebar extends Component<SidebarProps, SidebarState> {
  constructor(props) {
    super(props)
  }

  unlisten = () => {};
  state = {
    isOpenView: true,
    hasLogo: false,
    createPortalComponent: false,
    createGroupPortalComponent: false,
    createMultiPortalUserComponent: false,
  };


  componentDidMount(): void {
    this.props.setPortal();
    const portalInfo = LocalStorageWrapped.getItem("selectedPortal");
    if (portalInfo && !_.isEmpty(portalInfo)) {
      this.props.setPortalSelected(portalInfo);
    }
    const selectedProject = LocalStorageWrapped.getItem("selectedProject");
    if( !_.isEmpty(selectedProject) && !_.isEmpty(portalInfo)){
      this.props.setProjectSelected(selectedProject);
      this.props.onTryFetchProjectList(portalInfo.slug);
    }
    this.unlisten = this.props.history.listen((location, action) => {
      if (action === "POP") {
        if(location.pathname.includes('details')){
          this.props.onClearProjectState();
        }else{
          this.props.removeSelectedPortal();
        }
      }
    });
  }

  componentWillUnmount() {
    // Stop listening to history changes
    if (this.unlisten) {
      this.unlisten();
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.portal.id !== this.props.portal.id) {
      try {
        if (this.props.portal.id) {
          await axiosObj.get(`${process.env.REACT_APP_API_HOST}portal/logo/${this.props.portal.id}`);
          this.setState({hasLogo: true});
        } else {
          this.setState({hasLogo: false});
        }
      } catch (error) {
        this.setState({hasLogo: false});
      }
    }
  }

  toggleCreatePortalComponent = ()=>{
    this.setState({createPortalComponent: !this.state.createPortalComponent});
  }

  toggleGroupPortalComponent = ()=>{
    this.setState({createGroupPortalComponent: !this.state.createGroupPortalComponent});

  }

  toggleMultiPortalUserComponent = ()=>{
    this.setState({createMultiPortalUserComponent: !this.state.createMultiPortalUserComponent});

  }

  render() {
    let selectedPortal = this.props.portalSelected;
    let project_list = this.props.project_list;
    let homeUrl = generatePortalSpecificUrl(this.props.portal, "/dashboard");
    if (this.props.isSuperUser) {
      homeUrl = generatePortalSpecificUrl(this.props.portal, "/admin");
    } else if (this.props.isPortalAdmin) {
      homeUrl = generatePortalSpecificUrl(this.props.portal, "/portal-admin");
    }
    let portalLink = "/portals";
    let portalLinkTitle = "Portals";
    if (this.props.isPortalAdmin) {
      portalLink = generatePortalSpecificUrl(this.props.portal, "/details/" + this.props.portal.slug);
      portalLinkTitle = this.props.portal.portalName;
    }
    return (
      <>
        <div className="sidebar_header">
          <NavLink to={homeUrl} onClick={() => {this.props.removeSelectedProject();this.props.removeSelectedPortal();}}>
            {!this.state?.hasLogo ?
              <img src={process.env.PUBLIC_URL + "/AUAV_new_admin_header_logo.png"} alt="logo"/> :
              <img src={process.env.REACT_APP_API_HOST + "portal/logo/" + this.props.portal.id} alt={"logo"}/>
            }
          </NavLink>
        </div>
        <div className="sidebar_scrollable_area"
            style={{
              maxHeight: 'calc(100vh - 50px)', // Adjust height as needed
              overflowY: 'auto',
            }}
        >
          <ul className="sidebar_nav">
            {this.props.isSuperUser &&
            <li>
              <NavLink to={portalLink} onClick={()=> {this.props.onClearProjectState();
              this.props.removeSelectedPortal();
              }} activeClassName= {this.props.projectSelected ? '' : "active"}>
                {portalLinkTitle}
              </NavLink>
              { selectedPortal &&
                <NavLink to= {`/details/${selectedPortal.slug}`} onClick={this.props.removeSelectedProject} style={{marginLeft: '10px'}}  activeClassName = {this.props.projectSelected ? '' : "active"}>{selectedPortal.name}</NavLink>
              }
              {
                project_list.length > 0 && this.props.projectSelected &&
                  project_list.map((project:ProjectProps,index:number) =>{
                    return<NavLink to={`/${selectedPortal.slug}/projects/update/${project.id}/${selectedPortal.slug}`} onClick={() =>this.props.setProjectSelected(project)} style={{marginLeft: '25px'}} activeClassName={this.props.projectSelected.name === project.name ? "active" : ''} key={index}>{project.name}</NavLink>
                      })
              }
            </li>
            }
            {this.props.isPortalAdmin &&
            <li style={{marginLeft: "1rem"}}>
            <a className="sidebar_title">Portal: {portalLinkTitle}</a>
                    <NavLink activeClassName="active" to={`${portalLink}`}>Projects</NavLink>
                    <NavLink activeClassName="active" to={`${portalLink}/portal-users`}>Portal Users</NavLink>
                    <NavLink activeClassName="active" to={`${portalLink}/portal-admins`}>Portal Admins</NavLink>
                    <NavLink to={generatePortalSpecificUrl(this.props.portal, "/pending-approvals")}>Pending approvals</NavLink>
            </li>
            }
            {!this.props.isSuperUser && !this.props.isPortalAdmin &&
            <li>
              <NavLink to={portalLink} activeClassName="active">
                {portalLinkTitle}
              </NavLink>
            </li>
            }
          </ul>
          <div className="sidebar-content-area">
            {this.props.isSuperUser &&  !this.props.portalSelected &&
                <div className='text-md'>
                  <span style={{marginLeft: '46px'}} onClick={this.toggleCreatePortalComponent}> Create Portal <FontAwesomeIcon icon={this.state.createPortalComponent ? faMinusCircle :faPlusCircle} /></span><br/>
                  <span onClick={this.toggleGroupPortalComponent}>Create Portal Group <FontAwesomeIcon icon={this.state.createGroupPortalComponent? faMinusCircle : faPlusCircle} /></span><br/>
                  <span style={{marginLeft: '58px'}} onClick={this.toggleMultiPortalUserComponent}>Create User <FontAwesomeIcon icon={this.state.createMultiPortalUserComponent? faMinusCircle : faPlusCircle} /></span>
                </div>
            }
            {
              this.props.isSuperUser && this.state.createPortalComponent &&
                <div style={{padding: "3px"}}>
                <CreatePortal toggleCreatePortalComponent={this.toggleCreatePortalComponent}/>
                </div>
            }
            {
                this.props.isSuperUser && this.state.createGroupPortalComponent &&
                <GroupPortals toggleGroupPortalComponent={this.toggleGroupPortalComponent}/>
            }
            {
                this.props.isSuperUser && this.state.createMultiPortalUserComponent &&
                <CreateMultiPortalUser/>
            }
        </div>
        </div>
      </>
    )
  }

}

const mapStateToProps = (state) => {
  return {
    portal: state.portal,
    isSuperUser: get(state.login.loggedInUser, "isSuperUser", false),
    isPortalAdmin: find(state.login?.loggedInUser?.portals, (portal) => portal.portal.toString() === state.portal.id.toString())?.isPortalAdmin || false,
    portalSelected: state.portalListReducer.portalSelected,
    project_list: state.project.project_list,
    projectSelected: state.project.projectSelected,
  }
};

const mapDispatchToProps = (dispatch) => {
    return {
      onClearProjectState: () => dispatch(clear_project_state()),
      removeSelectedPortal: () => dispatch(remove_selected_portal()),
      setPortal: () => dispatch(actions.setPortalonReload()),
      setPortalSelected: (portal) => dispatch(set_portal_selected(portal)),
      onTryFetchProjectList: (portal_name) => dispatch(get_project_list(portal_name)),
      setProjectSelected: (project: ProjectProps) => dispatch(set_project_selected(project)),
      removeSelectedProject: ()=> dispatch(clear_selected_project_state()),
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Sidebar));
